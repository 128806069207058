@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400&display=swap);
*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body{
  font-family: "Poppins",sans-serif;
  font-size: 16px;
}

.hero-section {
  margin-top: 40px;margin-bottom: 50px;
}

.hero-section .card{
  padding: 10px;
}

.navbar-navbar{
  margin-bottom: 20px;
}

.d-flex-col{
  display: flex;
  flex-direction: column;
}

.d-flex-row{
  display: flex;
  flex-direction: row;
}

.ck.ck-content.ck-editor__editable {
    height: 250px;
}

.quill{
  height: 200px;
}

.hero-section .card {
  padding: 20px 20px 0;
  font-family: "Poppins",sans-serif;
  font-size: 14px;
line-height: 20px;
box-shadow: 1px 3px 9px #cecece;
flex-direction: row;
flex-wrap: wrap;padding: 40px 20px 20px;
}
.hero-section .card .first-section {
  margin-bottom: 35px;
}
.hero-section .card p { margin-bottom: 0;}
.first-section a {
  margin-bottom: 1px;font-family: "Poppins",sans-serif;
  display: block;
  font-size: 19px;
text-transform: capitalize;
font-weight: 500;
line-height: 25px;
}
.first-section a:hover { text-decoration: none;}

.second-section.col-md-4 i:nth-child(1) {
  margin-bottom: 6px; }
  .second-section span i {
    padding-right: 10px;
}
.dforum-btn {
  margin-bottom: 25px;
}
.third-section img {
  margin-right: 14px;
}
.third-section .activity-time {
  font-size: 11px;
}
.thread-details h2 { margin-bottom: 25px; margin-top: 15px;}
.forum-slug-list .btn.btn-primary {
  margin-bottom: 23px;
}
.forum-slug-list .card .row {
  align-items: center;
}
.no-gap p { margin-bottom: 0 !important;}
.forum-slug-list .card .row {
  align-items: center;
  padding: 12px 15px 0;
}
.align-top.profile-img {
  margin-right: 14px;
}
span.list-meta-wrap {
  font-size: 12px;
}
.thread-details .d-flex-row p {
  margin-bottom: 0px;
}
.thread-details.container .row {
  align-items: center;
}
.d-flex-row .profile-img2{
  margin-right: 15px;
}
.thread-details .card .row {
  padding: 10px 15px 0;
}
.thread-details .creator-date {
  font-size: 12px;
}
.thread-details .creator-email {
  font-size: 13px;
}
.thread-description-content {
  font-size: 14px;
}
.thread-details.container {
  padding-bottom: 50px;
}
.thread-details .btn.btn-primary { margin-top: 15px;}
.forum-slug-list {
  padding-bottom: 50px;
}
.navbar-navbar{
     background-color: rgba(0,120,210,.75) !important;
}
.navbar-dark .navbar-brand img {
  height: 84px;
}
.navbar-expand .navbar-nav a {
  color: #fff !important;
}

.paginationBttns {
  display: flex;
  margin: 40px 0;
}
.paginationBttns li{
  list-style: none;
}
.paginationBttns a {
  padding: 10px;
  margin: 8px;
  border-radius: 5px;
  border: 1px solid #409ADD;
  color: #409ADD;
  cursor: pointer;
}

.paginationBttns a:hover {
  color: #409ADD !important;
  background-color: #fff;
  text-decoration: none;
}

.paginationActive a {
  color: #fff !important;
  background-color: #409ADD;
}

.paginationDisabled a,
.paginationDisabled a:hover {
  color: #fff !important;
  background-color: grey;
  cursor: none;
  border-color: #fff !important;
}

.nav-item.show.dropdown .dropdown-menu.show {
    left: -60px;
}

.action-items{
 /* height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;*/
  cursor: pointer;
}

body{
  
}

#preloader{
  background:linear-gradient(to right, #409ADD, #9198e5);
  display:flex;
  justify-content:center;
  align-items:center;
  width:100%;
  min-height:100vh;
}
.preloarder{
  position:relative;
  width:100px;
  height:70px;
  display:inline-block;
  -webkit-transform-origin:center;
          transform-origin:center;
  -webkit-animation:preloarder 1.5s linear infinite;
          animation:preloarder 1.5s linear infinite;
}
.preloarder .SpanLoad{
  display:flex;
  justify-content:center;
}
.preloarder span{
  position:absolute;
  width:15px;
  height:15px;
  background:#fff;
  -webkit-transform-origin:center 35px;
          transform-origin:center 35px;
  border-radius:50%;
}
.preloarder span:first-child{
  opacity:.1;
}
.preloarder span:nth-child(2){
  -webkit-transform:rotate(36deg);
          transform:rotate(36deg);
  opacity:.2;
}
.preloarder span:nth-child(3){
  -webkit-transform:rotate(72deg);
          transform:rotate(72deg);
  opacity:.3;
}
.preloarder span:nth-child(4){
  -webkit-transform:rotate(108deg);
          transform:rotate(108deg);
  opacity:.4;
}
.preloarder span:nth-child(5){
  -webkit-transform:rotate(144deg);
          transform:rotate(144deg);
  opacity:.5;
}
.preloarder span:nth-child(6){
  -webkit-transform:rotate(180deg);
          transform:rotate(180deg);
  opacity:.6;
}
.preloarder span:nth-child(7){
  -webkit-transform:rotate(216deg);
          transform:rotate(216deg);
  opacity:.7;
}
.preloarder span:nth-child(8){
  -webkit-transform:rotate(252deg);
          transform:rotate(252deg);
  opacity:.8;
}
.preloarder span:nth-child(9){
  -webkit-transform:rotate(288deg);
          transform:rotate(288deg);
  opacity:.9;
}
.preloarder span:nth-child(10){
  -webkit-transform:rotate(324deg);
          transform:rotate(324deg);
  opacity:1;
}

@-webkit-keyframes preloarder{
  0%{
    -webkit-transform:rotate(0deg) scale(1);
            transform:rotate(0deg) scale(1);  
  }
  100%{
    -webkit-transform:rotate(360deg) scale(1);
            transform:rotate(360deg) scale(1);  
  }
}

@keyframes preloarder{
  0%{
    -webkit-transform:rotate(0deg) scale(1);
            transform:rotate(0deg) scale(1);  
  }
  100%{
    -webkit-transform:rotate(360deg) scale(1);
            transform:rotate(360deg) scale(1);  
  }
}
.d-flex-col.inner-forum-list {
  flex-direction: unset;
  align-items: center;
  font-size: 12px;
}
.d-flex-col.inner-forum-list i {
  padding-right: 9px;
}
.first-section.col-md-4 p {
  display: none;
}


@media (max-width: 767px)
{
  .hero-section .card { font-size: 11px;}
}
